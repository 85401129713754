import type { JSX } from 'preact';
import Star from './Star';

export default function Grading(props: { grading: number }): JSX.Element {
  const { grading } = props;

  return (
    <div className="page-album-grading">
      <Star full={grading >= 1} />
      <Star full={grading >= 2} />
      <Star full={grading >= 3} />
      <Star full={grading >= 4} />
      <Star full={grading >= 5} />
    </div>
  );
}
