import { Controller } from '@hotwired/stimulus';
import { supportsApplePay, supportsGooglePay } from '../model/payment_methods';

export default class extends Controller {
  async connect(): Promise<void> {
    const method = this.data.get('method');
    switch (method) {
      case 'applePay':
        if (await supportsApplePay()) {
          this.show();
        }
        break;
      case 'gpay':
        if (await supportsGooglePay()) {
          this.show();
        }
        break;
      default:
        break;
    }
  }

  private show(): void {
    this.element.classList.remove('u-hidden');
  }
}
