import { useMemo } from 'preact/hooks';

type I18nParams = Record<string, string | number>;

export type I18nClient<T extends object> = {
  t: (key: keyof T, params?: I18nParams) => string;
};

const interpolationRegex = /%{(\w+)\}/g;

function createI18n<T extends object>(translations: T): I18nClient<T> {
  type InterpolationFunction = (params?: I18nParams) => string;
  const store: Record<string, InterpolationFunction> = {};

  // Dynamically create functions for every key that interpolates the values into the string
  for (const [key, translation] of Object.entries(translations)) {
    if (interpolationRegex.test(translation)) {
      const fn = new Function(
        'params',
        `return \`${translation.replace(interpolationRegex, '$${params.$1 || "missing param $1"}')}\``
      );
      store[key] = fn as InterpolationFunction;
    } else {
      // Shortcut: If the translation does not have any params, we can just return it and use a "native" function
      store[key] = (() => translation) as InterpolationFunction;
    }
  }

  return {
    t(key, params = {}) {
      const fn = store[key as string];
      if (!fn) {
        return `missing key "${String(key)}"`;
      }
      return store[key as string]?.(params);
    },
  };
}

export function useI18n<T extends object>(translations: T): I18nClient<T> {
  return useMemo(() => createI18n(translations), [translations]);
}
