type FormInputElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement
  | HTMLButtonElement;

$(document).on('change', '.js-preflight', (event) => {
  const form = event.target.form as HTMLFormElement;
  form.requestSubmit();
  form
    .querySelectorAll<FormInputElement>('input, select, textarea, button')
    .forEach((el) => {
      el.disabled = true;
    });
});
