import type { JSX } from 'preact';
import { useState, useEffect, useLayoutEffect } from 'preact/hooks';
import classNames from 'classnames';
import MainItem from './MainItem';
import SelectItem from './SelectItem';
import { useI18n, type I18nClient } from '../lib/i18n';
import { useMediaQuery } from '../lib/useMediaQuery';

interface Item {
  id: number;
  description: string | null;
  grading: number;
  gradingLabel: string;
  priceHtml: string;
}

export interface AlbumPanelContentTranslations {
  condition: string;
  coverAndMediaGrading: string;
  gradingNotice: string;
  price: string;
  addToCart: string;
  chooseConditionFromPrice: string;
  chooseCondition: string;
  chooseOtherCondition: string;
  conditionNote: string;
  inclVatPlus: string;
  shippingCosts: string;
  gradingInformation: string;
}

interface Props {
  record: {
    items: Item[];
  };
  t: AlbumPanelContentTranslations;
  href: {
    shippingPath: string;
    gradingsPath: string;
  };
}

export type AlbumI18nClient = I18nClient<AlbumPanelContentTranslations>;

export default function AlbumPanelContent(props: Props): JSX.Element {
  const { record } = props;
  const i18n = useI18n(props.t);

  const [selectedItem, setSelectedItem] = useState(
    record.items[0] as Item | undefined
  );
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 740px)');

  const otherItemsCount = record.items.length - 1;

  // On mobile, we show a modal and want to display all the items in it, so the user does not get lost. On desktop, the selected item is already visible at the top.
  const recordItems = isMobile
    ? record.items
    : record.items.filter((item) => item !== selectedItem);

  useEffect(() => {
    if (!isMobile) return;

    // disable body scrolling when condition list is open on mobile
    if (isOpen) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    } else {
      const scrollY = document.body.style.top;
      if (!scrollY) return;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY) * -1);
    }
  }, [isMobile, isOpen]);

  useLayoutEffect(() => {
    // @ts-expect-error: This function is provided by discount.js.coffee to refresh funky price rendering
    window.discounted();
  });

  if (!selectedItem) {
    return <div>No available item</div>;
  }

  const toggleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  const changeSelection = (item: Item) => () => {
    setIsOpen(false);
    setSelectedItem(item);
  };

  return (
    <>
      <input type="hidden" name="item_id" value={selectedItem?.id} />

      <div className="stack stack--large">
        <div
          className={classNames('condition', { 'condition--is-open': isOpen })}
        >
          <div
            className={classNames('condition-table', {
              'condition-table--is-open': isOpen,
              'condition-table--has-note': selectedItem.description,
            })}
          >
            <MainItem
              item={selectedItem}
              i18n={i18n}
              toggleOpen={toggleOpen}
              otherItemsCount={otherItemsCount}
              isOpen={isOpen}
              href={props.href}
            />
            <div
              className={classNames('page-album-list condition-list', {
                'condition-list--is-open': isOpen,
                'condition-list--has-note': selectedItem.description,
              })}
            >
              <div className="condition-list__scroll-wrapper">
                <header className="condition-list__header">
                  <p className="text-next text-next--heading-3-bold text-next--uppercase text-next--color-label-caption-strong">
                    {i18n.t('chooseCondition', { count: otherItemsCount })}
                  </p>
                  <button
                    className="page-album-button page-album-button--close"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  ></button>
                </header>
                <div className="condition-list__content stack stack--medium stack--tablet-gap-0">
                  {recordItems.map((item) => (
                    <SelectItem
                      item={item}
                      i18n={i18n}
                      href={props.href}
                      onClick={changeSelection(item)}
                      isSelected={item === selectedItem}
                      key={item.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {selectedItem.description && (
            <div className="condition__note stack stack--small">
              <div className="text-next text-next--dropdown-album-caption text-next--color-label-caption-dim">
                {i18n.t('conditionNote')}
              </div>
              <div className="text-next text-next--dropdown-album-paragraph">
                {selectedItem.description || '—'}
              </div>
            </div>
          )}
        </div>
        <div className="stack stack--regular stack--tablet-flex stack--tablet-row-reverse stack--tablet-gap-24">
          <button
            className="page-album-button page-album-button--primary page-album-button--cart text-next text-next--button-default text-next--uppercase text-next--color-button-label-primary-default text-next--full-width text-next--tablet-width-auto"
            type="submit"
          >
            {i18n.t('addToCart')}
          </button>
          {otherItemsCount > 0 && (
            <button
              className="page-album-button page-album-button--secondary text-next text-next--button-default text-next--uppercase text-next--color-button-label-secondary-default text-next--full-width text-next--tablet-width-auto"
              type="button"
              onClick={toggleOpen}
            >
              {i18n.t('chooseConditionFromPrice')}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
